<template>
  <div v-if="data && data.id" class="order">
    <div class="comp-info flex">
      <img v-if="data.factoryComp.logo" @click="toCheckQualification(data.factoryComp.id)"
        :src="imgUrl + data.factoryComp.logo+'!width_250px'"/>
        <img v-else :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" />
      <div class="img-right flex-1">
        <div class="name"
          @click="toCheckQualification(data.factoryComp.id)"
        >{{data.factoryComp.name}}</div>
        <div class="flex">
          <div class="data">
            <div class="data-item">交货准时<span>{{data.factoryComp.onTimeRate}}%</span>
            </div>
            <div class="data-item">上新率<span>{{data.factoryComp.pubNewRate}}%</span>
            </div>
            <div class="data-item">累计销售<span>{{data.factoryComp.saleSumAmount | priceFilt}}</span>
            </div>
          </div>
          <div class="flex-1" style="text-align:right;padding-right:20px;">
            <!-- <el-button @click="applyWhite()" size="small" type="primary">申请白名单</el-button> -->
          </div>
        </div>
        <div class="label-wrap">
          <label v-for="(tag, i) in tags.fwbq" :key="i" :class="'color-' + randomInt(4)">{{tag.v}}</label>
        </div>
      </div>
    </div>
    <div class="info-btm">
      <div class="right">
        <a @click="get_phone(data.factoryComp.id)">
          <i class="iconfont icon-dianhuabeifen"></i> 联系方式（{{phoneNum && phoneNum.total}}）</a>
        <a @click="toCheckQualification(data.factoryComp.id)">查看更多资质</a>
      </div>
      <div v-if="data.factoryComp.aptitude && data.factoryComp.aptitude.real_check.expired_at > 0">
        <div class="item">
          <span>
            <i class="el-icon-check"></i>
          </span>已实地认证
        </div>
        <label>{{data.factoryComp.aptitude.real_check.expired_at | moment('YYYY/MM/DD')}}</label>
      </div>
      <!-- <div>
        <template v-if="data.photoSample && data.photoSample.length>0">
          <div class="item">
            <span><i class="el-icon-check"></i></span>已打样
          </div>
          <a @click="authentication()">查看打样</a>
        </template>
        <template v-else>
          <div class="item grey">
            <span><i class="el-icon-close"></i></span>未打样
          </div>
          <a  @click="invite()">邀请打样</a>
        </template>
      </div> -->
    </div>
    <div class="h">工艺能力：</div>
    <div v-if="data.factoryComp.aptitude && data.factoryComp.aptitude.relation" class="gyzl-block">
      <div v-for="n in data.factoryComp.aptitude.relation.ability_make" :key="n.id">
        <label>
          {{n.name}}
          <b v-if="n.primary">主</b>
        </label>
        <span v-if="n.quota && n.quota.length>0">
          <span
            v-for="(q,i) in n.quota"
            :key="i"
          >{{q.name}}{{q.value&&('：' + (typeof q.value !== 'string' ?q.value+';':q.value))}}</span>
        </span>
      </div>
    </div>
    <div class="h">规格选择</div>
    <div class="guige-block">
      <!-- <div v-if="spec && spec.length>0" class="check-guige">
        <div v-for="(item,idx) in spec" :key="idx" class="flex">
          <label>{{item.name}}</label>
          <div class="flex-1">
            <div v-for="(m, i) in item.items" :key="i" class="item"
              :class="[m.isShow?'':subIndex[idx] == i?'active':'']"
              @click="specificationBtn(m,idx,$event,i)">{{m.name}}</div>
          </div>
        </div>
      </div> -->
      <ItemSpec v-if="data && data.skus && data.skus.length>0" :skus="data.skus" :catId="catId" @skuItem="sku_item"></ItemSpec>
      <div v-if="skuItem" class="qoh">
        <div v-if="skuItem.mes">
          <span>现货量<b>{{skuItem.mes.stockCount}}</b></span>
          <span>工时<b>{{skuItem.mes.workTime}}</b></span>
        </div>
        <div v-if="skuItem.mes" class="capacity">
          <div v-for="(item, i) in skuItem.mes.futures" :key="i">
            <label>{{item.name}}剩余产能/总产能</label>
            <div>
              <div class="progress-top">
                <span>总产能：{{item.total}}</span>
                <span>剩余：{{item.count}}</span>
              </div>
              <div class="progress-container">
                <div class="progress-bar" :style="{width: (item.count / item.total * 100) + '%'}"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="price-table">
          <ItemQuote :quote="skuItem.prices"></ItemQuote>
        </div>
      </div>
    </div>
    <div v-if="skuItem && skuItem.prices && skuItem.prices.length>0" class="amount">￥{{totalPrice | priceFilt}}</div>
    <div class="flex">
      <div class="btn-block flex-1 flex flex-align-center flex-justify-between">
        <div class="amount-wrap">
          <label>订购数量</label>
          <div>
            <el-input-number v-model="totalNum" :disabled="!skuFlag" :precision="0" :min="1" placeholder="数量"></el-input-number>
          </div>
        </div>
        <el-button type="primary" :disabled="!skuFlag" @click="addCart()">加入购物车</el-button>
      </div>
      <div class="btn-block flex flex-center">
        <el-button type="primary" @click="addCury()">添加到分销商品库</el-button>
      </div>
      <div class="btn-block flex flex-center">
        <el-button type="primary" @click="addCollect()">
          <i class="iconfont" :class="collectStat ? 'icon-shoucangxiao' : 'icon-shoucang'"></i>
          收藏
        </el-button>
      </div>
    </div>
    <div v-if="fj && fj.length>0" class="h">商品附件</div>
    <Comp4 v-if="fj && fj.length>0" :items="fj" />

      <el-dialog :title="popTitle" :visible.sync="popVisible" 
          width="500px" :destroy-on-close="true">
          <ApplyWhite v-if="popTitle=='申请白名单'" :factoryId="data.factoryCompId" @hide="popVisible=false"></ApplyWhite>
          <ItemPhone v-if="popTitle=='联系方式'" :type="compType" :compId="compId" @hide="popVisible=false"></ItemPhone>
          <DrawDesign v-if="popTitle=='打样认证'" v-model="data.photoSample" :type="'view'" :itemId="data.id" @hide="popVisible=false"></DrawDesign>
      </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
import { uniqBy, findIndex } from "lodash";
import { getPhoneNum } from "@/service/company";
import { getCatDetail } from '@/service/item';
import { getFactoryItems } from "@/service/mall";
import { addToPurCar, countPurCar, addFav, delFav, statFav } from '@/service/sale';
import { addFxProduct } from '@/service/sharer';
import Comp4 from "../common/Comp4.vue";
import ItemQuote from '@/components/factory/ItemQuote.vue';
import ItemSpec from "./ItemSpec.vue";
import DrawDesign from '@/components/factory/DrawDesign.vue';
import ItemPhone from '@/components/comp/ItemPhone.vue';
import ApplyWhite from '@/components/comp/ApplyWhite.vue';

export default {
  components: {
    Comp4,
    ItemSpec,
    ItemQuote,
    DrawDesign,
    ItemPhone,
    ApplyWhite
  },
  props: {
    data: Object,
    type:String
  },
  data() {
    return {
      imgUrl:IMG_URL_PRE,
      staticUrl:STATIC_URL_PRE,
      popVisible: false,
      popTitle: '',
      queryOpt:{
          where:{
              ids:null,
              key:null,
              catIds:null,
              parentId:null,
          },
          page:{
              pageNumber:1,
              pageCount:3,
          },
      },
      catId: null,
      totalNum: 1, //订购数量,
      fj: [],// 商品附件
      skuItem: null,// 选择规格后的数据
      skuFlag: false,
      compId: null,
      compType: null,
      phoneNum: '', // 查看电话号码的数量
      collectStat: false, // 收藏状态 布尔值
    };
  },
  async created() {
    this.getCollect();
    if (this.type) {
      this.phoneNum = await getPhoneNum({comp_id:this.data.factoryComp.id,channel:2});
    } else {
      this.phoneNum = await getPhoneNum({comp_id:this.data.factoryComp.id,channel:1});
    }
    this.refreshItems();
    this.catId = this.data.id;
    console.log("data",this.data);
  },
  mounted() {
    
  },
  computed: {
    tags() {
      // const {
      //   aptitude: {
      //     ability_service: { tags }
      //   }
      // } = this.data.factoryComp;
      if (this.data.factoryComp && this.data.factoryComp.aptitude) {
        let tags = this.data.factoryComp.aptitude.ability_service;
        return tags;
      } else {
        return "";
      }
    },
    totalPrice() {
        let total = 0;
        if (this.skuItem && this.skuItem.prices) {
          this.skuItem.prices.forEach((e,idx) => {
            // total = this.totalNum*e.price;
            if (idx==this.skuItem.prices.length-1 && this.totalNum>=e.start && e.end==0 || e.end==null) {
              total = this.totalNum*e.price;
            } else if (idx < this.skuItem.prices.length-1 && e.end && this.totalNum>=e.start && this.totalNum<=e.end) {
                total = this.totalNum*e.price;
            }
          });
        }
        total = Math.floor(total * 100) / 100;
        return total;
    },
  },
  methods: {
    sku_item(item) {
      this.skuItem = item;
      if (this.skuItem && this.skuItem.prices && this.skuItem.prices.length>0) {
        this.buddle_sort(this.skuItem.prices);
        this.skuFlag=true;
      } else {
        this.skuFlag=false;
      }
      console.log(item);
    },
    buddle_sort(arr) {
        let len = arr.length - 1;
        for (let j = 0; j < len; j++) {
            for (let i = 0; i < len - j; i++) {
                if (arr[i].start > arr[i + 1].start) {
                    [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]]
                }
            }
        }
    },
    // 附件商品
    refreshItems() {
      console.log(this.queryOpt);
      this.queryOpt.where.parentId = [this.data.id];
      getFactoryItems(this.queryOpt).then(rst=>{
          if (rst.rows && rst.rows.length>0) {
            this.total = rst.count;
            this.fj = rst.rows;
          }
          console.log("fj",this.fj);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    // 查询商品收藏状态
    getCollect() {
      statFav({factory_product_no: this.data.id}).then(rst=>{
        console.log("statFav",rst);
          this.collectStat = rst.is_exist;
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    randomInt(n) {
      return Math.floor(Math.random() * n + 1);
    },
    toCheckQualification(id) {
      window.open('/zizhi/view/'+id);
    },
    applyWhite(item) {
      this.popTitle = "申请白名单";
      this.popVisible = true;
    },
    get_phone(id) {
      if (this.type) {
        this.compType = 2;
      } else {
        this.compType = 1;
      }
      this.compId = id;
      this.popTitle = "联系方式";
      this.popVisible = true;
    },
    invite() {
      this.$message.success("已邀请打样");
    },
    // 添加到收藏
    addCollect() {
      if (this.collectStat) {
        delFav({factory_product_no:this.data.id}).then(rst => {
            console.log('delFav',rst);
            this.collectStat = false;
            this.$message.success("取消收藏成功");
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        });
      } else {
        addFav({factory_product_no:this.data.id}).then(rst => {
            console.log('addFav',rst);
            this.collectStat = true;
            this.$message.success("添加收藏成功");
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        });
      }
    },
    // 添加到分销商品库
    addCury() {
      addFxProduct({factory_product_no:this.data.id}).then(rst => {
          console.log('addFxProduct',rst);
          this.$router.push('/agent/agentGoods');
          this.$message.success("加入分销商品库成功");
      }).catch(err => {
          console.log(err);
          this.$message.error(err.message);
      });
    },
    // 加入购物车
    addCart:_.debounce(function() {
      if (this.totalPrice==0) {
        this.$message.error("请选择范围内的数量");
        return;
      }
      let obj = {
        itemId:this.data.id,
        itemSkuId:this.skuItem.id,
        factoryId:this.data.factoryComp.id,
        count:this.totalNum,
      }
      if (this.totalNum && this.totalNum>0 && this.totalPrice>0) {
          addToPurCar(obj).then(rst => {
              console.log('addToPurCar',rst);
              this.getShopNum();
          }).catch(err => {
              console.log(err);
              this.$message.error(err.message);
          });
      } else {
          this.$message.error("请添加商品");
      }
    },1000),
    getShopNum() {
        countPurCar().then(rst => {
            console.log('countPurCar',rst);
            this.$store.state.ShopNum = rst;
            this.$message.success("放入采物车成功");
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        });
    },
    authentication(item) {
        this.popTitle = "打样认证";
        this.popVisible = true;
    },
  }
};
</script>

<style scoped lang="less">
.order {
  background-color: #fff;
  padding: 16px 11px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  .comp-info {
    display: flex;
    margin-bottom: 16px;
    img {
      width: 160px;
      height: 160px;
      margin-right: 36px;
      cursor: pointer;
    }
    .img-right {
      .name {
        color: #4d4d4d;
        font-size: 26px;
        font-weight: bold;
        cursor: pointer;
      }
      .data {
        width: 284px;
        height: 60px;
        display: flex;
        align-items: center;
        .data-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 33.333%;
          background: rgba(245, 245, 245, 0.5);
          border-radius: 6px;
          span {
            font-size: 18px;
            margin-top: 8px;
            color: #f66f6a;
          }
        }
      }
      .label-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 450px;
        margin-top: 10px;
        label {
          width: 88px;
          height: 24px;
          border-radius: 12px;
          margin: 0 24px 12px 0;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &.color-1 {
            background: rgba(1, 111, 255, 0.2);
            color: #016fff;
          }
          &.color-2 {
            background: rgba(43, 186, 174, 0.2);
            color: #2bbaae;
          }
          &.color-3 {
            background: rgba(230, 162, 60, 0.2);
            color: #e6a23c;
          }
          &.color-4 {
            background: rgba(246, 111, 106, 0.2);
            color: #f66f6a;
          }
        }
      }
    }
  }
  .info-btm {
    position: relative;
    .right {
      position: absolute;
      right: 19px;
      z-index: 1;
    }
    > div {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 30px;
      .item {
        display: flex;
        margin-right: 8px;
        &.grey {
          span {
            background-color: #808080;
          }
        }
        span {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #e3ab51;
          margin-right: 8px;
          i {
            font-size: 14px;
            color: #fff;
          }
        }
      }
      a {
        margin-left: 32px;
      }
      label {
        font-size: 12px;
        color: #ccc;
      }
    }
  }
  .h {
    height: 24px;
    background: #f5f5f5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-top: 20px;
  }
  .gyzl-block {
    color: #808080;
    padding-top: 16px;
    > div {
      margin-top: 7px;
      display: flex;
      padding-left: 35px;
      > span {
        width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > span {
          margin-right: 10px;
        }
      }
      label {
        margin-right: 83px;
        display: flex;
        position: relative;
        flex-shrink: 0;
        b {
          width: 16px;
          height: 16px;
          border-top-left-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #f66f6a;
          position: absolute;
          top: -6px;
          right: -18px;
          color: #fff;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .guige-block {
    padding-left: 35px;
    .qoh {
      margin-top: 29px;
      > div {
        &:first-child {
          color: #4d4d4d;
          font-size: 16px;
          span {
            margin-left: 180px;
            &:first-child {
              margin-left: 0;
            }
            b {
              color: #f66f6a;
              margin-left: 16px;
            }
          }
        }
      }
      .capacity {
        > div {
          display: flex;
          align-items: center;
          margin-top: 14px;
          label {
            width: 176px;
            color: #292929;
          }
          > div {
            .progress-top {
              display: flex;
              justify-content: space-between;
              color: #808080;
              font-size: 16px;
            }
            .progress-container {
              width: 217px;
              height: 4px;
              background-color: #e6e6e6;
              border-radius: 2px;
              .progress-bar {
                width: 0;
                height: 4px;
                border-radius: 2px;
                background-color: #016fff;
              }
            }
          }
        }
      }
    }
    .price-table {
      width: 612px;
      border: 1px solid #ccc;
      color: #808080;
      margin-top: 24px;
      .thead {
        display: flex;
        border-bottom: 1px solid #ccc;
        height: 24px;
        color: #4d4d4d;
        > div {
          flex: 1;
          display: flex;
          align-items: center;
          &:first-child {
            span {
              width: 226px;
              text-align: center;
            }
          }
          &:last-child {
            justify-content: flex-end;
            span {
              width: 184px;
              text-align: center;
            }
          }
        }
      }
      .tbody {
        > div {
          display: flex;
          > div {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 5px;
            &:first-child {
              span {
                width: 226px;
                text-align: center;
              }
            }
            &:last-child {
              justify-content: flex-end;
              span {
                width: 184px;
                text-align: center;
                color: #ee1818;
              }
            }
          }
        }
      }
    }
  }
  .amount {
    padding-right: 79px;
    font-size: 25px;
    color: #f66f6a;
    line-height: 40px;
    text-align: right;
    margin-top: 16px;
  }
  .btn-block {
    height: 80px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-top: 16px;
    margin-right: 14px;
    .amount {
      font-size: 24px;
      color: #f66f6a;
    }
    .amount-wrap {
      display: flex;
      align-items: center;
      color: #4d4d4d;
      margin-left: 35px;
      label {
        font-size: 18px;
        font-weight: bold;
        margin-right: 20px;
      }
      > div {
        display: flex;
        align-items: center;
        i {
          color: #4d4d4d;
          cursor: pointer;
        }
        .el-input {
          margin: 0 16px;
          width: 95px;
        }
      }
    }
    .el-button {
      margin: 0 4px;
    }
  }
  .btn-block:last-child {
    margin-right: 0;
  }
  .good-swiper {
    margin-top: 16px;
  }
}
</style>