<template>
  <div class="good" @click="toDetail(item.id)">
    <img v-if="item.photoRender" :src="imgUrl + item.photoRender[0]+'!width_250px'" />
    <div class="img-btm">
      <div class="name">{{item.name}}</div>
      <div class="feature">
        <span>{{item.style&&item.style.name}}</span>
        <span>{{item.cat&&item.cat.name}}</span>
      </div>
      <div class="price">
        <span>￥{{item.startPrice | priceFilt}}</span>起
      </div>
      <div class="pv">已有{{item.statistics&&item.statistics.uv}}人查看</div>
    </div>
  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
export default {
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      }
    },
    type: {
      type: String,
      default: "factory"
    }
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE
    };
  },
  methods: {
    toDetail(id) {
      // location.href = `/sale/mall/${this.type}_detail/${id}`;
      this.$router.push(`/sale/mall/${this.type}_detail/${id}`);
    }
  }
};
</script>

<style scoped lang="less">
.good {
  color: #808080;
  border: 1px solid rgba(112, 112, 112, 1);
  width: 189px;
  cursor: pointer;
  img {
    display: block;
    width: 187px;
    height: 187px;
  }
  .img-btm {
    padding: 7px 11px;
  }
  > div {
    margin-top: 5px;
  }
  .name {
    font-size: 13px;
    color: #4d4d4d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .feature {
    font-size: 10px;
    display: flex;
    span {
      padding: 0 8px;
      border-right: 1px solid #ccc;
      line-height: 1;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .price {
    font-size: 12px;
    span {
      color: #ee1818;
      font-size: 16px;
      margin-right: 3px;
    }
  }
  .pv {
    font-size: 9px;
  }
}
</style>