import { postToItemServer } from './common';
/**---------------------------列表代理商分组----------------------------------
 * 列表代理商分组
 * @param {*} opt
 */
export async function listAgencyGroup(opt) {
    let res = await postToItemServer("/factory/list-agency-group", opt);
    return res;
}
/**
 * 创建代理商分组
 * @param {*} obj
 */
export async function addAgencyGroup(obj) {
    let item = await postToItemServer("/factory/add-agency-group", obj);
    return item;
}
/**
 * 修改代理商分组
 * @param {*} obj
 */
export async function updateAgencyGroup(obj) {
    let item = await postToItemServer("/factory/update-agency-group", obj);
    return item;
}
/**
 * 删除代理商分组
 * @param {*} obj
 */
export async function delAgencyGroup(group_id) {
    let item = await postToItemServer("/factory/del-agency-group", {group_id: group_id});
    return item;
}

/**---------------------------列表代理商----------------------------------
 * 列表代理商
 * @param {*} opt
 */
export async function listAgencyByGroup(opt) {
    let res = await postToItemServer("/factory/list-agency-by-group", opt);
    return res;
}
/**
 * 修改代理状态
 * @param {*} obj
 */
export async function statAgency(obj) {
    let item = await postToItemServer("/factory/stat-agency", obj);
    return item;
}
/**
 * 列表代理商分组商品
 * @param {*} obj
 */
export async function listAgencyGroupProduct(obj) {
    let item = await postToItemServer("/factory/list-agency-group-product", obj);
    return item;
}
/**
 * 添加代理商分组商品
 * @param {*} obj
 */
export async function addAgencyGroupProduct(obj) {
    let item = await postToItemServer("/factory/add-product-to-agency-group", obj);
    return item;
}

/*---------------------------销售公司----------------------------------*/
/**
 * 列表我公司申请的代理商分组
 * @param {*} obj
 */
export async function listAgency(obj) {
    let item = await postToItemServer("/seller/list-agency", obj);
    return item;
}
/**
 * 列表代理商分组的商品
 * @param {*} obj
 */
export async function saleAgencyGroupProduct(obj) {
    let item = await postToItemServer("/seller/list-agency-group-product", obj);
    return item;
}
/**
 * 申请成为工厂的代理商
 * @param {*} obj
 */
export async function applyAgency(obj) {
    let item = await postToItemServer("/factory/apply-agency", obj);
    return item;
}