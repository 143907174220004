import { postToSaleServer } from './common';
/**
 * 查询商品列表（弃用）
 */
export async function getItems(opt){
    return await postToSaleServer('/mall/item/list',opt);
}
/**
 * 根据id查询具体商品（弃用）
 */
export async function getItem(id){
    return await postToSaleServer('/mall/item/get/'+id);
}

/**
 * 查询工厂款商品列表
 */
export async function getFactoryItems(opt){
    return await postToSaleServer('/mall/item/factory/list',opt);
}
/**
 * 查询设计师合作款商品列表
 */
export async function getDesignItems(opt){
    return await postToSaleServer('/mall/item/design/list',opt);
}
/**
 * 查询工厂款商品详情
 */
export async function getFactoryItem(id){
    return await postToSaleServer('/mall/item/factory/get/'+id);
}
/**
 * 查询设计师合作款商品详情
 */
export async function getDesignItem(id){
    return await postToSaleServer('/mall/item/design/get/'+id);
}
/**
 * 集市首页需要展示的商品
 * newDesignItems 新设计
 * hotSaleItems 热销款
 * desigXhItems 设计师现货款
 * moreFactItems 更多热门工厂款
 */
export async function getIndex(){
    return await postToSaleServer('/mall/index');
}