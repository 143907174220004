<template>
  <div>
    <div class="zt-block block-1">
      <Comp1 v-if="data && data.id" :data="data" />
    </div>
    <div class="zt-block block-2">
      <div v-if="data && data.id" class="block-main">
        <div class="block-left">
          <div class="sucai">
            <div class="tit">商品参数</div>
            <div class="flex" style="color:#808080;margin-bottom:8px;">
              <div style="width:300px;" v-if="data.cat">商品类目：{{data.cat.name}}</div>
              <div v-if="data.style">商品风格：{{data.style.name}}</div>
            </div>
            <div class="flex" style="color:#808080;margin-bottom:8px;">
              <div style="width:300px;" v-if="data.moduleNo">型号：{{data.moduleNo}}</div>
              <div v-if="data.dimension && data.dimension != '[]'">商品尺寸：<label v-for="(item,idx) in JSON.parse(data.dimension)" :key="idx">{{item}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></div>
            </div>
            <div class="attr flex">
              <div class="flex" v-for="(attr,idx) in data.attrs" :key="idx" style="width:300px;color:#808080;margin-bottom:8px;">
                <div>{{attr.name}}：</div>
                <div v-for="(value,n) in attr.values" :key="n">
                  <span v-if="n>0">，</span><span>{{value.name}}</span>
                </div>
              </div>
            </div>
            <div style="color:#808080;margin-bottom:8px;">
              <div class="flex" style="width:600px;" v-if="data.intro">
                <div>商品描述：</div>
                <div class="flex-1">{{data.intro}}</div>
              </div>
            </div>
          </div>
          <div class="sucai">
            <div class="tit">商品素材</div>
            <div v-if="data && data.photoStory && data.photoStory.length>0" class="img-list">
              <img v-for="(img, i) in data.photoStory" :key="i" :src="imgUrl + img+'!width_800px'" />
            </div>
            <div v-else class="img-list">
                <img src="../../../../assets/images/desgin/zwt.png" alt="">
            </div>
          </div>
          <div class="order-block">
            <div class="order-main">
              <Comp2 v-if="data && data.id" :data="data" :type="'_self'" />
            </div>
          </div>
          <div v-if="recommend && recommend.length>0" class="other-goods">
            <div class="tit">
              <span>我厂其他商品推荐</span>
            </div>
            <div>
              <Comp3 v-for="(item,idx) in recommend.slice(0, 3)" :key="idx" :item="item" />
            </div>
          </div>
        </div>
        <div class="side">
          <div v-if="list1 && list1.length>0">
            <h3>同系列查看</h3>
            <div class="list">
              <Comp3 v-for="(item,idx) in list1.slice(0, 3)" :key="idx" :item="item" />
              <a @click="goto('/sale/mall/home/factory')">查看更多</a>
            </div>
          </div>
          <div v-if="list2 && list2.length>0">
            <h3>搭配推荐</h3>
            <div class="list">
              <Comp3 v-for="(item,idx) in list2.slice(0, 3)" :key="idx" :item="item" />
              <a @click="goto('/sale/mall/home/factory')">查看更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧提示栏 -->
    <FloatPanel v-if="data && data.id"></FloatPanel>

  </div>
</template>

<script>
import Comp1 from "../common/Comp1.vue";
import Comp2 from "../common/Comp2.vue";
import Comp3 from "../common/Comp3.vue";
import FloatPanel from '../Panel.vue';
import { getFactoryItem,getFactoryItems } from "@/service/mall";
import { IMG_URL_PRE } from "@/config";

export default {
  components: {
    Comp1,
    Comp2,
    Comp3,
    FloatPanel
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      checkList: [],
      data: null,
      queryOpt:{
          where:{
              ids:null,
              key:null,
              catIds:null,
              excludeIds:null,
              isMaster:true,
              seriesIds:null,
              compIds:null,
          },
          page:{
              pageNumber:1,
              pageCount:3,
          },
          order: [
              ["createdAt","desc"]
          ],
      },
      recommend: [],
      list1: [],
      list2: []
    };
  },
  watch: {
    $route(to,from){
      this.data = null;
      this.refreshItem();
    }
  },
  created() {
    this.refreshItem();
  },
  mounted() {
    
  },
  methods:{
    // toDetail(id) {
    //   this.$router.push("/sale/mall/home/factory_detail/"+id);
    //   console.log(id);
    // },
    goto(path){
        this.$router.push(path);
    },
    refreshItem() {
      getFactoryItem(this.$route.params.id).then(rst=>{
          if (rst) {
              this.data=rst;
              if (rst.series && rst.series.id) {
                this.refreshItems(rst.series.id,rst.id);
              }
              this.refreshItems1(rst.id);
              this.refreshItems2(rst.factoryComp.id,rst.id);
          }
          // console.log(rst);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    // 同系列商品
    refreshItems(id,excludeId) {
      this.queryOpt.where.seriesIds = [id];
      this.queryOpt.where.excludeIds = [excludeId];
      getFactoryItems(this.queryOpt).then(rst=>{
          if (rst.rows && rst.rows.length>0) {
            this.total = rst.count;
            this.list1 = rst.rows;
          }
          // console.log("getFactoryItems",this.list1);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    // 搭配推荐商品
    refreshItems1(excludeId) {
      this.queryOpt.where.excludeIds = [excludeId];
      getFactoryItems(this.queryOpt).then(rst=>{
          if (rst.rows && rst.rows.length>0) {
            this.total = rst.count;
            this.list2 = rst.rows;
          }
          // console.log("getFactoryItems",this.list2);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    // 我厂其他商品推荐
    refreshItems2(id,excludeId) {
      this.queryOpt.where.compIds = [id];
      this.queryOpt.where.excludeIds = [excludeId];
      getFactoryItems(this.queryOpt).then(rst=>{
          if (rst.rows && rst.rows.length>0) {
            this.total = rst.count;
            this.recommend = rst.rows;
          }
          // console.log("getFactoryItems",this.list);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
  },
};
</script>

<style scoped lang="less">
.zt-block {
  min-width: 1250px;
}
.comp-block {
  display: flex;
  padding: 8px 16px;
  img {
    width: 120px;
    height: 120px;
    margin-right: 24px;
  }
  .img-right {
    .name {
      color: #4d4d4d;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .label-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 450px;
      label {
        width: 88px;
        height: 24px;
        border-radius: 12px;
        margin: 0 24px 12px 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        &.color-1 {
          background: rgba(1, 111, 255, 0.2);
          color: #016fff;
        }
        &.color-2 {
          background: rgba(43, 186, 174, 0.2);
          color: #2bbaae;
        }
        &.color-3 {
          background: rgba(230, 162, 60, 0.2);
          color: #e6a23c;
        }
        &.color-4 {
          background: rgba(246, 111, 106, 0.2);
          color: #f66f6a;
        }
      }
    }
  }
}
.block-2 {
  padding: 16px 0;
  color: #4d4d4d;
  .block-main {
    background-color: #f5f5f5;
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .block-left {
      .sucai {
        padding: 16px 0 0 188px;
        .tit {
          font-size: 18px;
          padding-bottom: 10px;
        }
        .img-list {
          width: 680px;
          img {
            width: 100%;
            display: block;
            margin-bottom: 30px;
          }
        }
      }
      .select-bar {
        padding: 9px 0;
        display: flex;
        background-color: #fff;
        margin: 23px 27px 8px;
        align-items: center;
        > div:first-child {
          color: #4d4d4d;
          padding: 9px 36px;
          border-right: 1px solid #707070;
          font-size: 18px;
          margin-right: 20px;
        }
      }
      .order-block {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 27px;
        width: 920px;
        margin-top: 47px;
        .comp-list {
          margin-right: 8px;
          padding: 8px 16px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          .comp-item {
            width: 118px;
            height: 160px;
            background-color: #fff;
            padding: 15px;
            margin-bottom: 8px;
            cursor: pointer;
            img {
              width: 88px;
              height: 88px;
            }
            div {
              margin-top: 8px;
              color: #4d4d4d;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
        }
        .order-main {
          width: 750px;
        }
      }
      .other-goods {
        display: flex;
        margin-left: 170px;
        flex-direction: column;
        padding: 24px 54px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 28px;
        .tit {
          margin-bottom: 24px;
          span {
            border-left: 5px solid #2bbaae;
            color: #4d4d4d;
            font-size: 16px;
            padding-left: 8px;
          }
        }
        > div:last-child {
          width: 633px;
          display: flex;
          justify-content: space-between;
          align-self: center;
        }
      }
    }
    .side {
      width: 253px;
      padding-top: 8px;
      margin-left: 23px;
      > div {
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
        h3 {
          width: 253px;
          height: 79px;
          background: rgba(230, 230, 230, 1);
          border: 1px solid rgba(204, 204, 204, 1);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #4d4d4d;
          font-size: 16px;
        }
        .list {
          padding: 25px 31px;
          background: rgba(245, 245, 245, 1);
          border: 1px solid rgba(204, 204, 204, 1);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          display: flex;
          flex-direction: column;
          align-items: center;
          .good {
            margin-top: 34px;
            &:first-child {
              margin-top: 0;
            }
          }
          a {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>