<template>
    <div class="quote" style="text-align: center;">
        <div class="flex quote-header">
            <div class="flex-1">数量</div>
            <div class="flex-1">单价</div>
        </div>
        <div v-if="prices && prices.length>0">
            <div class="range-item flex" v-for="(item,idx) in prices" :key="idx">
                <div class="range flex-1">
                    <div v-if="item.range" class="start">{{item.range.start}}</div>
                    <div v-if="item.start" class="start">{{item.start}}</div>
                    <div class="line">
                        <span v-if="item.end">—</span>
                        <span v-else>以上</span>
                    </div>
                    <div class="end">
                        <!-- <span v-if="item.range">{{item.range.end}}</span> -->
                        <span v-if="item.end">{{item.end}}</span>
                    </div>
                </div>
                <div class="flex-1">￥{{item.price}}</div>
            </div>
        </div>
        <div v-else class="range-item flex">
            <div class="range flex-1">
                <div class="start">--</div>
                <div class="line">
                    <span>以上</span>
                </div>
            </div>
            <div class="flex-1">￥--</div>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: "quote",
            event: "change"
        },
        props: ['quote'],
        data() {
            return {
                prices:null,
            }
        },
        watch: {
            quote: {
                handler() {
                    this.prices = JSON.parse(JSON.stringify(this.quote));
                    this.buddle_sort(this.prices);
                },
                deep: true
            },
        },
        computed: {},
        created () {
            this.prices = JSON.parse(JSON.stringify(this.quote));
            this.buddle_sort(this.prices);
            // console.log(this.prices);
        },
        methods: {
            buddle_sort(arr) {
                let len = arr.length - 1;
                for (let j = 0; j < len; j++) {
                    for (let i = 0; i < len - j; i++) {
                        if (arr[i].start > arr[i + 1].start) {
                            [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]]
                        }
                    }
                }
            },
        }
    }
</script>
<style scoped>
    .quote {
        background: #F5F5F5;
        padding-bottom: 10px;
    }
    .quote-header {
        font-size:14px;
        font-weight:bold;
        color:rgba(77,77,77,1);
        line-height:40px;
    }
    /* --------列表价格周期---------- */
    .range-item {margin:0 auto 12px;height: 20px;line-height: 20px;}
    .range-item:last-child {margin-bottom: 0;}
    .range>div {
        display: inline-block;
        vertical-align: top;
        min-width: 30px;text-align: center;
        font-size: 12px;color: #4D4D4D;
    }
    .range .start,.range .end {background: #fff;}
</style>