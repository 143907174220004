<template>
  <div v-if="data && data.id" class="good-block flex">
    <div class="img-viewer">
      <ItemImages width="420px" :imgs="data.photoRender" :preUrl="imgUrl" />
    </div>
    <div class="good-info flex-1">
      <div class="name">
        {{data.name}}
        <!-- <span>型号：SL2020003</span> -->
      </div>
      <div class="price">
        <b>销售价</b>
        <span>￥{{data.startPrice | priceFilt}}</span>
        <div>起</div>
      </div>
      <!-- <div class="feature-wrap">
        <label v-if="data.cat">{{data.cat.name}}</label>
        <label v-if="data.style">{{data.style.name}}</label>
      </div> -->
      <div class="design">
        <div class="tit">设计理念：</div>
        <p>{{data.designIdea}}</p>
      </div>
      <div class="up">
        <!-- <el-button @click="showPutaway(data)" type="primary">我要上架</el-button> -->
      </div>
    </div>

    <el-dialog :destroy-on-close="true" :class="{'dialogActive':popTitle == '加入采购车'}" :title="popTitle" :visible.sync="popVisible">
        <ItemPopShop v-if="popTitle == '请选择需要发布的店铺'" 
          :productID="productID" @hide="popVisible=false"></ItemPopShop>
    </el-dialog>
    
  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
import ItemImages from "@/components/ItemImages.vue";
import ItemPopShop from '../ItemPopShop.vue';
export default {
  components: {
    ItemImages,ItemPopShop
  },
  props: {
    data: Object
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popTitle:"",
      popVisible:false,
      productID:null,
    };
  },
  computed: {
    price() {
      return this.data.startPrice;
    }
  },
  methods: {
    showPutaway(item) {
        this.popTitle="请选择需要发布的店铺";
        this.popVisible=true;
        this.productID = item.id;
    },
  }
};
</script>

<style scoped lang="less">
.good-block {
  padding: 24px 62px;
  width: 1322px;
  margin: 0 auto;
  .img-viewer {
    margin-right: 37px;
    width: 420px;
    flex-shrink: 0;
    flex-grow: 0;
    .img-preview {
      width: 420px;
      height: 420px;
      border: 1px dashed #ccc;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .img-thumb-list {
      margin-top: 10px;
      font-size: 0;
      overflow: auto;
      width: 420px;
      white-space: nowrap;
      .img-wrap {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 1px solid #ccc;
        cursor: pointer;
        margin-right: 18px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .good-info {
    font-size: 14px;
    .name {
      color: #292929;
      font-size: 24px;
      margin-left: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .price {
      color: #4d4d4d;
      font-size: 16px;
      display: flex;
      align-items: center;
      margin: 20px 0 0 16px;

      b {
        margin-right: 16px;
      }
      span {
        color: #ee1818;
        margin-right: 8px;
      }
      div {
        color: #4d4d4d;
        font-size: 12px;
      }
    }
    .feature-wrap {
      color: #ccc;
      margin-top: 20px;
      label {
        border-right: 1px solid #707070;
        padding: 0 16px;
        line-height: 1;
        color: #707070;
        &:last-child {
          border-right: 0;
        }
      }
    }
    .design {
      max-width: 700px;
      background-color: rgba(#2bbaae, 0.05);
      border-radius: 4px;
      padding: 8px 16px;
      margin-top: 24px;
      .tit {
        color: #4d4d4d;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
    .up {
      margin: 144px 0 0 24px;
    }
  }
}
</style>