<template>
    <div>
        <div v-if="type" class="drawDesign-header" style="padding-top:0;">工厂打样图</div>
        <div v-else class="drawDesign-header" style="padding:0;">商品打样图</div>
        <div v-if="photo_sample" class="drawDesign-img">
            <div v-if="type">
                <div class="img-item" v-for="(item,idx) in photo_sample" :key="idx">
                    <img :src="imgUrl+item" alt="">
                </div>
            </div>
            <div v-else style="padding-top:12px;">
                <UploadImgs v-model="ruleForm.photo_sample" prefix='product/proof'></UploadImgs>
            </div>
        </div>
        <div class="flex flex-justify-around" style="padding-top:30px;">
            <div><el-button @click="hide()" size="small" type="primary" plain>取消</el-button></div>
            <div v-if="type"><el-button @click="hide()" size="small" type="primary">确认</el-button></div>
            <div v-else><el-button @click="submitForm()" size="small" type="primary">确认</el-button></div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { setProductSampleImg } from '@/service/factory';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    export default {
        components: {
            UploadImgs
        },
        model: {
            prop: "photo_sample",
            event: "change"
        },
        props: ['photo_sample','itemId','type'],
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                ruleForm:{
                    photo_sample:this.photo_sample,
                    product_no:this.itemId,
                }
            }
        },
        watch:{
            photo_sample:{
                handler() {
                    this.ruleForm = {
                        photo_sample:this.photo_sample,
                        product_no:this.itemId,
                    };
                },
                deep: true
            }
        },
        computed: {},
        created() {
            // console.log(this.photo_sample);
        },
        mounted() {},
        methods: {
            submitForm:_.debounce(function(){
                let obj = {
                    product_no:this.itemId,
                    photo_sample:this.photo_sample,
                }
                this.ruleForm.photo_sample = this.ruleForm.photo_sample.reduce((pre,cur)=>{
                    pre.push(cur.replace(IMG_URL_PRE,''));
                    return pre;
                },[]);
                setProductSampleImg(this.ruleForm).then(rst => {
                    this.hide();
                    this.$emit("onSuccess");
                    this.$emit("change",this.photo_sample);
                    this.$message.success("提交成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },1000),
            hide() {
                this.$emit('hide',);
            },
        }
    }
</script>
<style scoped>
    .drawDesign-header {
        font-size: 14px;
        color: #4D4D4D;
        line-height: 20px;
        padding: 20px 10px;
    }
    .drawDesign-img {
        /* padding: 20px 10px 0 10px; */
        background: #fff;
    }
    .img-item {
        display: inline-block;
        vertical-align: top;
        width: 128px;
        height: 128px;
        margin: 0 10px 20px 10px;
    }
    .img-item>img {
        width: 100%;
        height: 100%;
    }
    .drawDesign-text {
        padding: 20px ;
        font-size: 14px;
        color: #4D4D4D;
        line-height: 20px;
        background: #fff;
    }
    .drawDesign-button {
        padding-top: 20px;
        text-align: center;
    }
</style>